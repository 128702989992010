import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import Home from './Home';

function App() {

  return (
    <>
      <Home />
      <ToastContainer/>
    </>
  )
}

export default App
