import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { rainbow } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ReactMarkdown from 'react-markdown';

const Home = () => {
  const [data, setData] = useState(JSON.parse(localStorage.getItem('chatData')) || []);
  const [prompt, setPrompt] = useState('');
  const [sendbtn, setSendBtn] = useState('send');
  const [newFile, setNewFile] = useState('');

  useEffect(() => {
    localStorage.setItem('chatData', JSON.stringify(data));
  }, [data]);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleSubmit = async () => {
    setSendBtn('sending');
    try {
      const formData = new FormData();
      formData.append('prompt', prompt);
      formData.append('file', newFile);

      const response = await fetch('https://public.lazybluffer.online/generate', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();
      const newData = [...data, { prompt, response: responseData.text }];
      setData(newData);
      setPrompt('');
      setNewFile(''); 
      setSendBtn('send');
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success('Copied');
      })
      .catch((err) => {
        toast.error('Error copying text to clipboard:', err);
      });
  };

  const handleChat = () => {
    localStorage.removeItem('chatData');
    window.location.reload();
  }
  const handleCode = () => {
    setPrompt('```\n\n```')
  }

  const handlePagePosition = () => {
    window.scrollTo(0, document.body.scrollHeight);
  }

  const isCode = (text) => {
    const jsRegex = /^(import|require|const|let|var|function|class|setTimeout|setInterval|console\.(log|warn|error|export|require_once|include|echo|print|function|if|else|elseif|foreach|for|while|import|package|class|public|private|protected|static|void|if|else|while|for|try|catch|finally|#include|namespace|class|public|private|protected|static|void|int|double|if|else|while|for|try|catch|throw|return|#include|void|int|char|float|double|if|else|while|for|switch|case|break|continue|return|using|namespace|class|public|private|protected|static|void|int|string|double|if|else|while|for|try|catch|finally|return))\s*\(.*\)|^\/\/|^\/\*/;
    const codeFenceRegex = /```|^(?:#{1,6}[^#].*)$/m;

    return jsRegex.test(text) || codeFenceRegex.test(text)
  };

  return (
    <div className="container d-flex flex-column-reverse ">
      <div className="d-flex align-items-center position-sticky bottom-0 container bg-white mt-3 z-3 ">
        <div className="mb-3 w-100">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Prompt
          </label>
          <button className='btn btn-secondary mx-3 my-3' onClick={handleChat}>New Chat</button>
          <button className='btn btn-secondary mx-3 my-3' onClick={handleCode}>Code</button>
          <input type='file' onChange={(e) => setNewFile(e.target.files[0])} />
          <textarea
            className="form-control"
            id="exampleFormControlTextarea1"
            rows={3}
            value={prompt}
            onChange={handlePromptChange}
          />
        </div>
        <button onClick={handleSubmit} className="btn btn-secondary heading-font ms-2 px-4">
          {sendbtn}
        </button>
      </div>
      <div className=" min-vh-100 bg-secondary-light pb-3 ">
        <h2 className='position-sticky top-0 bg-dark text-white   ps-3  py-3 heading-font z-3'>New Gen Ai Model :</h2>
        {data.map((item, index) => (
          <div key={index} className='px-3'>
            <div className="d-flex -items-end bg-light px-3 py-2 rounded border border-1 ">
              <div>
                <h3 className='heading-font'>Prompt:</h3>
                {isCode(item.prompt) ? (
                  <SyntaxHighlighter style={rainbow}>
                    {item.prompt}
                  </SyntaxHighlighter>
                ) : (
                  <ReactMarkdown>{item.prompt}</ReactMarkdown>
                )}
              </div>
            </div>
            <div className='bg-light my-3 px-3 py-2 rounded border border-1 position-relative '>
              <h3 className='heading-font'>Generated Response:</h3>
              <div className='d-flex justify-content-between align-items-start '>
                {isCode(item.response) ? (
                  <SyntaxHighlighter style={rainbow} className='w-100  '>
                    {item.response}
                  </SyntaxHighlighter>
                ) : (
                  <pre>
                    <ReactMarkdown className='text-wrap roboto-regular '>
                      {item.response}
                    </ReactMarkdown>
                  </pre>
                )}
                <button className="btn btn-secondary heading-font position-absolute mt-2" style={{right:'2%',top:'0%'}} onClick={() => handleCopy(item.response)}>
                  copy
                </button>
              </div>
            </div>
          </div>
        ))}
        <button
          className={'position-fixed btn btn-secondary rounded-circle d-block'}
          style={{ right: '50%', bottom: '20%' }}
          onClick={handlePagePosition}
        >
          <i className="fa-solid fa-arrow-down"></i>
        </button>
      </div>
    </div>
  );
};

export default Home;
